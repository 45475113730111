<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="videoWrap preview">
    <i class="el-icon-video-play"></i>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.videoWrap {
  width: 100%;
  height: 100%;
  user-select: none;
  display: table;
}
</style>
